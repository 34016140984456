import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCurrentUser } from '../../hooks/useCurrentUser';
import { LinkButton } from '../../components/LinkButton';
import gql from 'graphql-tag';
import { useGetGamesForHomeQuery, useGetUserGroupsByProfileIdQuery } from '../../generated/graphql';
import { GameLine } from '../../components/GameLine';
import { PersonalProfileHeader } from '../../components/PersonalProfileHeader';
import { Loader } from '../../components/Loader';
import { Button } from '../../components/Button';
import { AddFriend } from '../../assets/icons';
import { useModal } from '../../components/Modal';
import { CreateGroupModal } from './groups';
import { useRouter } from 'next/router';
import { DEFAULT_GROUP_PICTURE } from '../../constants';

gql`
  query getGamesForHome($profileId: uuid!) {
    next: match(limit: 5, order_by: { playedAt: asc }, where: { isMatchOver: { _eq: false } }) {
      ...GameLineData
    }
    prev: match(limit: 5, order_by: { playedAt: desc }, where: { isMatchOver: { _eq: true } }) {
      ...GameLineData
    }
  }

  ${GameLine.fragments.GameLineData}
`;

export const Home = () => {
  const { t } = useTranslation(['common', 'home']);
  const { profile, loading: fetchingUser } = useCurrentUser();
  const [isOpen, setOpen, setClose] = useModal();
  const router = useRouter();

  const [{ data, fetching }] = useGetGamesForHomeQuery({
    requestPolicy: 'cache-and-network',
    variables: {
      profileId: profile?.id ?? '',
    },
  });

  const [{ data: userGroupsData, fetching: fetchingUserGroups }, refetchGroups] = useGetUserGroupsByProfileIdQuery({
    variables: {
      profileId: profile?.id,
    },
    requestPolicy: 'cache-and-network',
  });

  const onGroupCreated = () => {
    refetchGroups({ requestPolicy: 'network-only' });
  };

  const userGroups = userGroupsData?.group;

  if (fetchingUser) {
    return <Loader />;
  }

  if (fetching || !data) {
    return <Loader />;
  }
  const { prev, next } = data;

  return (
    <>
      <div className="flex overflow-hidden">
        <div className="flex-1 relative z-0 flex overflow-hidden space-x-6">
          <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none space-y-6" tabIndex={0}>
            <PersonalProfileHeader profileId={profile?.id} />
            <div className="bg-white rounded-lg">
              <div className="pl-6 pt-6 font-black text-lg ">{t('home:nextGames')}</div>
              <div className="divide-y pl-6">
                {next.map((m) => (
                  <GameLine className="pr-6" game={m} key={m.id} />
                ))}
              </div>
              <div className="flex items-center justify-center -pl-6 py-4 border-t">
                <LinkButton variant="blue" to="/app/matches?tab=upcoming">
                  {t('home:seeAllNextGames') as string}
                </LinkButton>
              </div>
            </div>
            <div className="bg-white rounded-lg">
              <div className="pl-6 pt-6 font-black text-lg ">{t('home:pastGames')}</div>
              <div className="divide-y pl-6">
                {prev.map((m) => (
                  <GameLine className="pr-6" game={m} key={m.id} />
                ))}
              </div>
              <div className="flex items-center justify-center py-4 border-t">
                <LinkButton variant="blue" to="/app/matches?tab=finished">
                  {t('home:seeAllPastGames') as string}
                </LinkButton>
              </div>
            </div>
          </main>
          <aside className="hidden relative xl:flex xl:flex-col flex-shrink-0 w-86 space-y-6">
            <div className="bg-white rounded-lg pt-6">
              <div className="font-black text-lg pl-6">{t('home:groupsList')}</div>
              <div className="divide-y pl-6">
                {userGroups?.length ? (
                  userGroups.map((group) => (
                    <div
                      className="flex py-5 group cursor-pointer"
                      key={group.id}
                      onClick={() =>
                        router.push({ pathname: '/app/groups/[groupSlug]', query: { groupSlug: group.slug } })
                      }
                    >
                      <img
                        src={group.photo_url ?? DEFAULT_GROUP_PICTURE}
                        alt="group"
                        className="rounded-full w-10 h-10"
                      />
                      <div className="flex flex-col ml-4">
                        <span className="font-semibold text-blue-500 group-hover:underline">{group.name}</span>
                        <span className="text-gray-500 text-sm">
                          {t('common:membersCount', { count: group.group_members_aggregate.aggregate?.count ?? 0 })}
                        </span>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-sm text-gray-500 mt-2 mb-6">{t('groups:youHaveNoGroupYet')}</div>
                )}
              </div>
              <div className="flex items-center justify-center py-4 border-t">
                {userGroups?.length ? (
                  <LinkButton variant="blue" to="/app/groups">
                    {t('home:seeAllMyGroups') as string}
                  </LinkButton>
                ) : (
                  <Button onClick={setOpen} variant="secondary" Icon={AddFriend}>
                    {t('groups:createAGroup')}
                  </Button>
                )}
              </div>
            </div>
          </aside>
        </div>
      </div>
      <CreateGroupModal onSuccess={onGroupCreated} isOpen={isOpen} setClose={setClose} />
    </>
  );
};
export default Home;
